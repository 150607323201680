import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { ImPhone } from 'react-icons/im';
import Obfuscate from 'react-obfuscate';

import Map from './Map';

const SidebarStyles = styled.aside`
  top: 0;
  align-self: flex-start;
  flex-basis: 330px;
  padding-top: 4rem;
  margin-bottom: 4rem;
  > * {
    margin-left: 3rem;
    margin-right: 2rem;
  }
  > .map {
    margin-right: 0;
  }
  h3 {
    margin-bottom: 0.3rem;
    font-size: 2.5rem;
  }

  address {
    font-style: normal;
    margin-bottom: 1rem;
  }

  iframe {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    flex-grow: 1;
    div,
    iframe {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-height: 1024px) {
    position: sticky;
  }
`;

export default function Sidebar() {
  return (
    <SidebarStyles>
      <h3>Kassenleistungen</h3>
      <ul>
        <li>Psychotherapie für Erwachsene</li>
      </ul>
      <h3>Selbstzahlerleistungen</h3>
      <ul>
        <li>
          <Link to="/paartherapie/">Paartherapie und Beratung</Link>
        </li>
        <li>Systemische Therapie und Beratung</li>
        <li>Coaching und Supervision</li>
      </ul>
      <h3>Kontakt</h3>
      <p>
        Unsere Telefonzeiten sind Dienstag und Mittwoch zwischen 12.00 und 12.50
        Uhr. Erstanfragen für eine Psychotherapie nehmen wir ausschließlich
        telefonisch zu diesen Zeiten entgegen.
      </p>
      <Obfuscate
        tel="004975197770715"
        className="button"
        obfuscateChildren={false}
        linkText="https://psychotherapieravensburg.de/datenschutz/"
        id="emailButton"
      >
        <span style={{ display: 'inline-block' }}>
          <ImPhone style={{ marginBottom: '-1px', height: '19px' }} /> 0751 /
          977 707 15
        </span>
      </Obfuscate>
      <p>
        Bei anderen Anfragen (alles außer Psychotherapie) können Sie uns auch
        eine E-Mail schreiben. Wir sind bemüht zeitnah auf Mailanfragen zu
        reagieren.
      </p>
      <p>
        Auf Grund zahlreicher Anfragen und hoher Auslastung kann sich eine
        Antwort verzögern. In diesem Fall bitten wir Sie, es telefonisch bei uns
        zu probieren. Bitte nennen Sie bei Mailanfragen immer eine
        Telefonnummer, um Sie gegebenenfalls zurück rufen zu können. Bitte
        beachten Sie, dass wir individuellen Rückrufbitten aus Kapazitätsgründen
        manchmal nicht nach kommen können. In diesem Fall bitten wir Sie
        ebenfalls während der Telefonzeiten anzurufen.
        <p>Danke für Ihr Verständnis.</p>
        <p>
          <Obfuscate
            email="kontakt@psychotherapieravensburg.de"
            obfuscateChildren={false}
            linkText="https://psychotherapieravensburg.de/datenschutz/"
            style={{ display: 'inline-block', textDecoration: 'underline' }}
          >
            <span>E-Mail schreiben</span>
          </Obfuscate>
        </p>
      </p>
      <p>We speak English. Hablamos español.</p>
      <h3>Praxis</h3>
      <address>
        Kornhausgasse 3<br />
        88212 Ravensburg
      </address>
      <Map className="map" />
    </SidebarStyles>
  );
}
