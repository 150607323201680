import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Iframe from 'react-iframe';

import styled from 'styled-components';

const MapStyles = styled.div`
  position: relative;
  div:last-child {
    position: absolute;
    background-color: rgba(51, 51, 51, 0);
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: 0.3s ease;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    cursor: pointer;
    text-align: center;
    p {
      font-size: 1.3rem;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &:hover div:last-child {
    opacity: 1;
    background-color: rgba(51, 51, 51, 0.8);
  }
  @media (hover: none) {
    div:last-child {
      opacity: 1;
      background-color: rgba(51, 51, 51, 0.6);
    }
  }
`;

export default function Map({ className }) {
  const [isThirdParty, setThirdParty] = useState(false);
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const mapRef = useRef(null);

  useEffect(() => {
    setDimensions({
      height: mapRef.current.offsetHeight,
      width: mapRef.current.offsetWidth,
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      staticMap {
        childFile {
          childImageSharp {
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  `);
  if (!isThirdParty) {
    return (
      <MapStyles
        onClick={() => setThirdParty(true)}
        className={className}
        ref={mapRef}
      >
        <Img
          fluid={data.staticMap.childFile.childImageSharp.fluid}
          alt="Karte der Umgebung der Psychotherapie Ravensburg"
        />
        <div>
          <strong>Klicken, um interaktive Karte zu laden</strong>
          <br />
          <p>
            Dabei wird eine Verbindung zu Google Maps hergestellt. Es könnten
            personenbezogene Daten übermittelt und Cookies gesetzt werden.
          </p>
        </div>
      </MapStyles>
    );
  }
  console.log(window.innerWidth);
  return (
    <Iframe
      url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2681.0332345629645!2d9.612001316271547!3d47.7808035839199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479baf1e116fb599%3A0xe6093bf4108bf67a!2sKornhausgasse%203%2C%2088212%20Ravensburg!5e0!3m2!1sde!2sde!4v1606895757486!5m2!1sde!2sde"
      width={dimensions.width}
      height={dimensions.height}
      frameBorder="0"
      allowfullscreen=""
      className="map"
      style={{ border: 0, margin: 0, width: '100%' }}
    />
  );
}
