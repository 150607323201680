import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const HeaderStyles = styled.div`
  max-width: 100%;
  margin: 1.6rem 6rem;
  display: flex;
  align-items: center;
  * {
    flex-grow: 1;
  }

  h1 {
    font-weight: 400;
    margin: 0.67em 0;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    margin: 1.6rem 2rem;
    h1 {
    }
    p {
      display: none;
    }
  }
`;

const HeaderImageWrapperStyles = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;;
  height: 0;
  padding-top: 35%;
  p {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0.7rem 0 0.7rem 1.8rem;
    width: calc(100% - 1.8rem);
    color: white;
    background: rgba(177, 177, 177, 0.8);
    backdrop-filter: blur(4px);
    span {
      margin-right: 10px;
    }
  }
  div {
    position: absolute;
    top: -33%;
    left: 0;
    width: 100%;
    height: 100%;
    }
  }
  @media (min-width: 768px) {
    p {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding-top: 48%;

    div {
      top: -23%;
    }
  }
`;

export default function Header({ showImage = true }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sarah-florian-such-portrait-both.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <HeaderStyles>
        <h1>
          <Link to="/">
            Praxis für <strong>Psychotherapie</strong>
          </Link>
        </h1>
        <p>
          Psychologische Psychotherapeuten
          <br />
          Sarah &amp; Florian Such
        </p>
      </HeaderStyles>
      {showImage && (
        <HeaderImageWrapperStyles>
          <Img
            fluid={data.file.childImageSharp.fluid}
            style={{ position: 'absolute', overflow: 'initial' }}
            imgStyle={{
              objectPosition: 'top center',
              minHeight: '100%',
              height: 'auto',
              width: '100%',
            }}
            alt="Sarah Such und Florian Such"
          />
          <p>
            <span>Psychologische Psychotherapeuten</span>
            <strong>Sarah &amp; Florian Such</strong>
          </p>
        </HeaderImageWrapperStyles>
      )}
    </>
  );
}
